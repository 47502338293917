// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCamTgT42WCjAwrhTNRBRVA5zm9EKKLlVs",
  authDomain: "klopm-a0d11.firebaseapp.com",
  databaseURL: "https://klopm-a0d11-default-rtdb.firebaseio.com",
  projectId: "klopm-a0d11",
  storageBucket: "klopm-a0d11.appspot.com",
  messagingSenderId: "543418718828",
  appId: "1:543418718828:web:78ccf99c1904026ebd1649",
  measurementId: "G-4SKRN6ZJLV"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };